import numeral from 'numeral'

export default {
  data () {
    return {
      loading: 1,
      barChartOptions: {
        series: [],
        labels: [],
        chart: {
          stacked: true,
          stackType: '100%',
          toolbar: { show: false }
        },
        plotOptions: {
          bar: {
            barHeight: 40,
            horizontal: true,
            dataLabels: { hideOverflowingLabels: false, maxItems: 100 }
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: { text: undefined },
        xaxis: {
          labels: { show: false },
          axisBorder: { show: false }
        },
        yaxis: {
          title: { text: undefined },
          labels: { show: false },
          axisBorder: { show: false }
        },
        grid: {
        show: false,
          padding: { top: -20, right: 0, bottom: -60, left: 0 }
        },
        fill: { opacity: 1 },
        dataLabels: {
          style: { fontSize: '14px' },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            opacity: 0.25
          },
          formatter: function(value){
            return numeral(value / 100).format('0.00%')
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: -30,
          offsetY: 0,
          fontSize: '14px',
          markers: { radius: 15 },
          itemMargin: { vertical: 0, horizontal: 0 },
          formatter: (status, chart) => {
            const value = chart.w.globals.series[chart.seriesIndex]
            const currency = this.isCurrency ? this.$n(value, 'currency') : value

            return this.formatLegend(status, currency)
          }
        },
        tooltip: {
          enabled: false
        },
        colors: [
          this.$vuetify.theme.themes.light.primary,
          this.$vuetify.theme.themes.light.accent,
          this.$vuetify.theme.themes.light.secondary
        ],
        responsive: [{
          breakpoint: 599,
          options: {
            chart: {},
            plotOptions: {},
            grid: {},
            legend: {
              fontSize: '12px',
              itemMargin: { vertical: 0, horizontal: 5 }
            }
          }
        }]
      }
    }
  },
  methods: {
    formatLegend (status, value){
      return `${status}<br><strong>${value}</strong>`
    }
  }
}
