export default {
  data () {
    return {
      loading: 1,
      radialBarChartOptions: {
        colors: [this.$vuetify.theme.themes.light.channelEmail],
        legend: { show: false },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              margin: 0,
              size: '55%'
            },
            dataLabels: {
              name: {
                color: this.$vuetify.theme.themes.light.darkGray,
                fontSize: '16px',
                offsetY: -20
              },
              value: {
                offsetY: -70,
                fontSize: '22px',
                color: this.$vuetify.theme.themes.light.darkGray
              }
            }
          }
        },
        grid: {
          show: false,
          padding: { top: -15 }
        },
        series: [],
        labels: []
      }
    }
  }
}
