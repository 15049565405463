<template>
  <section class="empty-state">
    <font-awesome-icon
      v-if="faIcon"
      :icon="icon" />
    <v-icon
      v-else
      :size="size"
      color="mediumGray">
      {{ icon }}
    </v-icon>
    <p class="mt-1">
      <slot />
    </p>
  </section>
</template>

<style lang="sass">
  .empty-state
    text-align: center
    color: $mediumGray
    width: 95%

    > .icon
      opacity: .5

    p
      margin-top: 20px
      margin-bottom: 0

    .svg-inline--fa
      font-size: 75px

</style>

<script>
  export default {
    props: {
      icon: {
        type: String,
        default: 'assignment_late'
      },
      size: {
        type: [String, Number],
        default: '100px'
      },
      faIcon: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
