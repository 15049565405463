<template>
  <intersect @enter="showElement">
    <v-card
      class="widget"
      :height="height">
      <header
        class="widget-header"
        :class="{ 'widget-header--center': isTitleCenter }">
        <h2 class="widget-title">
          {{ title }}
        </h2>
        <v-divider />
      </header>
      <div
        class="widget-content py-0 px-0"
        :class="{ 'widget-content--small': isContentSmall }">
        <slot />
      </div>
    </v-card>
  </intersect>
</template>

<script>
  import Intersect from 'vue-intersect'

  export default {
    components: {
      Intersect
    },
    props: {
      title: {
        type: String,
        default: 'Title here ...'
      },
      height: {
        type: String,
        default: '100%'
      },
      isTitleCenter: {
        type: Boolean,
        default: false
      },
      isContentSmall: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      showElement () {
        this.$emit('screen', true)
      }
    }
  }
</script>

<style lang="sass">
  @import '../../../assets/style/widgets'

  .widget
    .absolute-center
      top: calc(50% + 25px)
</style>
