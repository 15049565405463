var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4 py-8"},[_c('widget-box',{attrs:{"height":"auto","title":"Faturamento"},on:{"~screen":function($event){return _vm.showElement($event)}}},[_c('transition',{attrs:{"name":"fade"}},[(!_vm.graph.series)?_c('loading',{staticClass:"absolute-center"}):_c('div',[(_vm.graph.series)?_c('div',[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs4":"","px-8":""}},[_c('v-select',{staticClass:"mt-2",attrs:{"items":_vm.monthList,"label":"Escolha o mês"},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('apexcharts',{staticClass:"px-4",attrs:{"type":"area","options":_vm.graph,"series":_vm.graph.series,"height":_vm.graph.chart.height}})],1)],1)],1):_c('empty-state',{staticClass:"fixed-center",attrs:{"size":"75px","icon":_vm.$root.icons.layout.empty_chart}},[_vm._v(" No momento não há dados ")])],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
var item = ref.item;
return [(item.icon === 'handshake')?_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":item.icon,"color":"#757575","size":"lg"}}):_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(" "+_vm._s(item.product)+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.$root.icons.link.info))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.cost, 'currency'))+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.discount, 'currency'))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.total, 'currency'))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }