export default {
  data () {
    return {
      loading: 1,
      donutChartOptions: {
        theme: {
          monochrome: {
            enabled: true,
            color: this.$vuetify.theme.themes.light.channelEmail
          }
        },
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
          textAnchor: 'start',
          offsetY: 5,
          itemMargin: { horizontal: 5 },
          labels: { color: this.$vuetify.theme.themes.light.darkGray },
          formatter: (status, chart) => {
            let value = chart.w.globals.series[chart.seriesIndex]
            let valuePercent = chart.w.globals.seriesPercent[chart.seriesIndex][0].toFixed(1)
            return `${value} ${status} - ${valuePercent}%`
          },
          markers: {
            size: 5,
            strokeWidth: 0,
            offsetX: 0,
            offsetY: 0,
            radius: 15
          }
        },
        dataLabels: { enabled: false },
        series: [],
        labels: [],
        plotOptions: {
          pie: {
            size: 140,
            donut: {
              size: '75%',
              background: 'transparent',
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Envios'
                }
              }
            }
          }
        },
        tooltip: { enabled: false },
        responsive: [{
          breakpoint: 599,
          options: {
            chart: { height: 380 },
            plotOptions: {
              pie: { size: 130 }
            },
            legend: {
              offsetY: 0,
              horizontalAlign: 'left',
              itemMargin: { vertical: 25, horizontal: 10 }
            }
          }
        }]
      }
    }
  }
}
