import EmptyState from '@/components/ui/EmptyState'
import WidgetBox from '@/components/ui/widgets/WidgetBox'
import Loading from '@/components/ui/Loading'
import VueApexCharts from 'vue-apexcharts'
import Intersect from 'vue-intersect'
import donutChartMixin from '@/mixins/charts/donut'
import radialBarChartMixin from '@/mixins/charts/radial-bar'
import barChartMixin from '@/mixins/charts/bar'

export default {
  mixins: [ donutChartMixin, radialBarChartMixin, barChartMixin ],
  props: {
    title: {
      type: String
    },
    channel: {
      type: String
    },
    attempt: {
      type: String,
      default: null
    },
    data: {
      type: [Array, Object]
    },
    isCurrency: {
      type: Boolean,
      default: false
    }
  },
  components: {
    apexcharts: VueApexCharts,
    WidgetBox,
    Loading,
    Intersect,
    EmptyState
  },
  computed: {
    hasData () {
      return this.totalValue > 0
    }
  }
}
