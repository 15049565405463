<template>
  <div class="px-4 py-8">
    <widget-box
      height="auto"
      title="Faturamento"
      @screen.once="showElement">
      <transition name="fade">
        <!-- <loading
          v-if="loading > 0"
          class="absolute-center"
        /> -->
        <loading
          v-if="!graph.series"
          class="absolute-center" />
        <div v-else>
          <div v-if="graph.series">
            <!-- <div v-if="hasData"> -->
            <v-layout row>
              <v-flex xs4 px-8>
                <v-select
                  v-model="selectedMonth"
                  :items="monthList"
                  label="Escolha o mês"
                  class="mt-2" />
              </v-flex>

              <v-flex xs12>
                <apexcharts
                  type="area"
                  :options="graph"
                  :series="graph.series"
                  :height="graph.chart.height"
                  class="px-4" />
              </v-flex>
            </v-layout>
          </div>
          <empty-state
            v-else
            size="75px"
            :icon="$root.icons.layout.empty_chart"
            class="fixed-center">
            No momento não há dados
          </empty-state>
        </div>
      </transition>

      <v-data-table
        class="elevation-1 mt-4"
        :headers="headers"
        :items="items"
        hide-default-footer>
        <template v-slot:item.product="{ item }">
          <font-awesome-icon
            v-if="item.icon === 'handshake'"
            :icon="item.icon"
            class="mr-2"
            color="#757575"
            size="lg" />
          <v-icon v-else class="mr-2">
            {{ item.icon }}
          </v-icon>
          {{ item.product }}
        </template>

        <template v-slot:item.description="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>{{ $root.icons.link.info }}</v-icon>
              </v-btn>
            </template>

            <span>{{ item.description }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.cost="{ item }">
          {{ $n(item.cost, 'currency') }}
        </template>
        <template v-slot:item.discount="{ item }">
          {{ $n(item.discount, 'currency') }}
        </template>
        <template v-slot:item.total="{ item }">
          {{ $n(item.total, 'currency') }}
        </template>
      </v-data-table>
    </widget-box>
  </div>
</template>

<script>
  import widgetsMixin from '@/mixins/widgets'

  export default {
    mixins: [ widgetsMixin ],

    data () {
      return {
        headers: [
          { text: 'Produto', value: 'product', sortable: false },
          { text: 'Descrição', value: 'description', sortable: false },
          { text: 'Utilização', value: 'use', sortable: false },
          { text: 'Custo', value: 'cost', sortable: false },
          { text: 'Desconto', value: 'discount', sortable: false },
          { text: 'Total', value: 'total', sortable: false }
        ],
        items: [
          { product: 'Casos', description: '', use: '', cost: '', discount: '', total: '', icon: this.$root.icons.link.cases },
          { product: 'Cartas', description: '', use: '', cost: '', discount: '', total: '', icon: this.$root.icons.channel.letter },
          { product: 'Sms', description: '', use: '', cost: '', discount: '', total: '', icon: this.$root.icons.channel.sms },
          { product: 'Negociação', description: '', use: '', cost: '', discount: '', total: '', icon: this.$root.icons.link.proposals },
          { product: 'Mediação - Voluntária', description: '', use: '', cost: '', discount: '', total: '', icon: this.$root.icons.link.proposals },
          { product: 'Mediação - Profissional', description: '', use: '', cost: '', discount: '', total: '', icon: this.$root.icons.link.proposals },
          { product: 'Enriquecimento de Dados', description: '', use: '', cost: '', discount: '', total: '', icon: this.$root.icons.link.chat },
        ],
        selectedMonth: 'current_month',
        monthList: [
          { text: 'Mês atual', id: 'current_month' },
          { text: 'Ültimo mês', id: 'last_month' },
          { text: 'Ültimos 3 meses', id: 'last_three_months' },
          { text: 'Acumulado no ano', id: 'last_year' },
        ]
      }
    },
    computed: {
      graph () {
        return {
          legend: {
            show: true
          },
          chart: {
            background: '#fff',
            width: '100%',
            height: 280
          },
          stroke: {
            curve: 'smooth',
            lineCap: 'butt',
            width: 2,
            dashArray: 0
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
          xaxis: {
            type: 'category',
            show: true,
            labels: {
              show: true,
            },
            categories: this.setMonth()
          },
          series: [{ name: 'Teste1', data: [5, 1, 3, 4, 20, 12, 7, 10] }, { name: 'Teste2', data: [3, 2, 7, 10, 14, 25, 19, 12] }],
          plotOptions: {
            bar: {
              endingShape: 'rounded',
              horizontal: false,
              columnWidth: '20%'
            }
          },
          dataLabels: {
            enabled: true
          }
        }
      }
    },
    mounted () {

    },
    methods: {
      setMonth () {
        const today = new Date()
        const isCurrentMonth = this.selectedMonth === 'Mês atual'

        if (isCurrentMonth || this.selectedMonth === 'Ültimo mês') {
          const date = isCurrentMonth ? today : new Date(today.setMonth(today.getMonth() - 1))
          const month = date.toLocaleString('default', { month: 'short' })
          const labels = Array.from({length: 30}, (x, i) => `${month} ${i
            + 1}`)

          return labels
        }

        if (this.selectedMonth === 'Ültimos 3 meses') {
          const current = new Date(today.setMonth(today.getMonth())).toLocaleString('default', { month: 'short' })
          const previous = new Date(today.setMonth(today.getMonth() - 1)).toLocaleString('default', { month: 'short' })
          const beforePrevious = new Date(today.setMonth(today.getMonth() - 2)).toLocaleString('default', { month: 'short' })

          return [beforePrevious, previous, current]
        }

        if (this.selectedMonth === 'Acumulado no ano') {
          return ['Jan', 'Fev', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        }
      },
      showElement () {

      }
    }
  }
</script>
